import React from "react"
import PropTypes from "prop-types"

import Link from "lib-vinomofo/components/link"
import StackedContainer from "lib-vinomofo/components/stacked-container"
import GridContainer from "lib-vinomofo/components/grid-container"
import {
  useWidthBreakpoints,
  Breakpoint,
} from "lib-vinomofo/hooks/use-breakpoints"

import {
  ArticleCard,
  ArticleCardImage,
  ArticleCardTitle,
  ArticleCardText,
  ArticleCardCTA,
} from "../../components/article-card"

const ArticleCardItem = ({ article, includeExcerpt }) => {
  const { thumbnail, title, slug, excerpt } = article

  return (
    <ArticleCard>
      <Link route={{ slug }}>
        <ArticleCardImage src={thumbnail} />
      </Link>
      <ArticleCardTitle>
        <Link route={{ slug }}>{title}</Link>
      </ArticleCardTitle>
      {includeExcerpt && <ArticleCardText>{excerpt}</ArticleCardText>}
      {includeExcerpt && (
        <ArticleCardCTA route={{ slug }}>Read more</ArticleCardCTA>
      )}
    </ArticleCard>
  )
}

const breakpoints = [Breakpoint("horizontal", [700, null])]

const ArticleGridWithBigOne = ({ articles }) => {
  const [breakpoint, breakpointRef] = useWidthBreakpoints(breakpoints)
  const [firstArticle, ...otherArticles] = articles

  const containerProps = {
    [null]: { space: 2 },
    horizontal: { space: 2, horizontal: true, distribute: "fill" },
  }

  return (
    <div ref={breakpointRef}>
      <StackedContainer {...containerProps[breakpoint]}>
        <ArticleCardItem includeExcerpt article={firstArticle} />

        <GridContainer numCols={2} space={2}>
          {otherArticles.map(article => (
            <ArticleCardItem key={article.slug} article={article} />
          ))}
        </GridContainer>
      </StackedContainer>
    </div>
  )
}

ArticleGridWithBigOne.propTypes = {
  articles: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      slug: PropTypes.string,
      thumbnail: PropTypes.string,
      excerpt: PropTypes.string,
    })
  ),
}

export default ArticleGridWithBigOne
