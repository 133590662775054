import React, { useState } from "react"
import { useQuery } from "@apollo/react-hooks"
import gql from "graphql-tag"

import StackedContainer from "lib-vinomofo/components/stacked-container"

import LoadMore from "../../components/load-more/load-more"
import ArticleGrid from "../../components/article-grid"

const LOADICLES = gql`
  query($cursor: Int, $quantity: Int) {
    postCollection(skip: $cursor, limit:$quantity, order: publishDate_DESC) {
      items {
        title
        excerpt
        marketsCollection {
          items {
            code
          }
        }
        imageObject: image {
          url
        }
        thumbnailObject: thumbnail {
          url
        }
        slug
      }
    }
  }
`

const updateQuery = (previousResult, { fetchMoreResult }) => {
  return {
    ...previousResult,
    postCollection: {
      ...previousResult.postCollection,
      items: [...previousResult.postCollection.items, ...fetchMoreResult.postCollection.items],
    }
  }
}

const LoadMoreArticles = ({ quantity }) => {
  const [isLoading, setLoading] = useState(false)

  const { data, loading, error, fetchMore } = useQuery(LOADICLES, {
    variables: { cursor: 0, quantity },
  })

  if (loading) return <LoadMore loading={true} />
  if (error) return <p>Error: ${error.message}</p>

  const articles = data.postCollection.items.map((article) => {
    const marketCode = article.marketsCollection.items.map((market) => market.code)

    if (marketCode.includes(process.env.GATSBY_SITE_MARKET.toLowerCase())) {
      return {
        ...article,
        image: article.imageObject.url,
        thumbnail: article.thumbnailObject.url,
      }
    }
  });

  return (
    <StackedContainer space={4}>
      <ArticleGrid articles={articles} />
      <LoadMore
        loading={isLoading}
        onClick={() => {
          setLoading(true)
          fetchMore({ variables: { cursor: articles.length }, updateQuery }).then(
            () => setLoading(false)
          )
        }}
      />
    </StackedContainer>
  )
}

const MoreArticles = () => {
  const [loadMoreArticles, setLoadMoreArticles] = useState(false)
  return loadMoreArticles ? (
    <LoadMoreArticles quantity={12} />
  ) : (
    <LoadMore onClick={() => setLoadMoreArticles(true)} />
  )
}

export default MoreArticles
