import React, { useState } from "react"
import PropTypes from "prop-types"

import Link from "../link"
import classNames from "../../utils/class-names"

import styles from "./cta-button.module.scss"

const CTAButton = ({ route, link, onClick, ...contentProps }) => {
  const { text, children, ...displayProps } = contentProps
  const [isHovered, setHoverState] = useState()

  const defaultStyles = {
    color: displayProps.textColor,
    backgroundColor: displayProps.backgroundColor,
    borderColor: displayProps.borderColor,
  }

  const hoverStyles = {
    color: displayProps.activeTextColor,
    backgroundColor: displayProps.activeBackgroundColor,
    borderColor: displayProps.activeBorderColor,
  }

  const currentStyles = isHovered ? hoverStyles : defaultStyles
  const classes = classNames([styles.button, displayProps.className])

  const ctaButton = (
    <span
      style={currentStyles}
      className={classes}
      onMouseEnter={() => setHoverState(true)}
      onMouseLeave={() => setHoverState(false)}
      onClick={onClick}
    >
      {text || children}
    </span>
  )

  if (route || link) {
    return (
      <Link route={route} link={link}>
        {ctaButton}
      </Link>
    )
  }
  return ctaButton
}

CTAButton.defaultProps = {
  external: false,
}

CTAButton.propTypes = {
  text: PropTypes.node,
  link: PropTypes.string,
  route: PropTypes.shape({ slug: PropTypes.string.isRequired }),
  textColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  borderColor: PropTypes.string,
  activeTextColor: PropTypes.string,
  activeBackgroundColor: PropTypes.string,
  activeBorderColor: PropTypes.string,
}

export default CTAButton
