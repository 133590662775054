import React from "react"
import PropTypes from "prop-types"

const WineGlass = ({ color }) => {
  return (
    <svg viewBox="0 0 40 58" xmlns="http://www.w3.org/2000/svg">
      <g fillRule="nonzero" fill="none">
        <path
          d="M34 56H21V41.95a19.98 19.98 0 0 0 19-20.97L39 .95A1 1 0 0 0 38 0H2a1 1 0 0 0-1 .95L0 20.98a19.98 19.98 0 0 0 19 20.97V56H6a1 1 0 0 0 0 2h28a1 1 0 1 0 0-2zM2 21.07L2.95 2h34.1L38 21.07a18.02 18.02 0 1 1-36 0z"
          fill="#D2D6DD"
        />
        <path
          d="M36 21.18l-.06-1.23c-.32-6.53-10.18-9.36-15.8-2.95-2.17 2.48-7.2 3.14-15.08 2a1 1 0 0 0-1 .95L4 21.18a16.03 16.03 0 1 0 32 0z"
          fill={color}
        />
      </g>
    </svg>
  )
}

WineGlass.propTypes = {
  color: PropTypes.string,
}

WineGlass.defaultProps = {
  color: "#77b204",
}

export default WineGlass
