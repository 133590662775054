import React from "react"
import PropTypes from "prop-types"

import SizingWrapper from "lib-vinomofo/components/sizing-wrapper"
import Link from "lib-vinomofo/components/link"
import FeaturedItemsContainer from "lib-vinomofo/components/featured-items-container"

import FeaturedItem from "../../components/featured-item"

import {
  useWidthBreakpoints,
  Breakpoint,
} from "lib-vinomofo/hooks/use-breakpoints"

const breakpoints = [Breakpoint("722px", [800, null])]

const FeaturedArticles = ({ articles }) => {
  const [breakpoint, breakpointRef] = useWidthBreakpoints(breakpoints)

  return (
    <div ref={breakpointRef}>
      <FeaturedItemsContainer
        title="Vinofiles"
        subtitle="Adventures in wine &amp; food"
      >
        {articles.map(({ title, slug, thumbnail, readingTime }) => (
          <Link key={slug} route={{ slug }}>
            <SizingWrapper height={breakpoint || "500px"}>
              <FeaturedItem
                title={title}
                tagline={readingTime}
                imageSrc={thumbnail}
              />
            </SizingWrapper>
          </Link>
        ))}
      </FeaturedItemsContainer>
    </div>
  )
}

FeaturedItemsContainer.propTypes = {
  articles: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      slug: PropTypes.string,
      thunbnail: PropTypes.string,
      excerpt: PropTypes.string,
    })
  ),
}
export default FeaturedArticles
