import React, { useRef } from "react"
import PropTypes from "prop-types"

import imageURL from "lib-vinomofo/utils/image-url"
import CTAButton from "lib-vinomofo/components/cta-button"
import StackedContainer from "lib-vinomofo/components/stacked-container"

import styles from "./article-block.module.scss"
import classNames from "lib-vinomofo/utils/class-names"

import {
  useWidthBreakpoints,
  Breakpoint,
} from "lib-vinomofo/hooks/use-breakpoints"

const layoutBreakpoints = [
  Breakpoint("vertical", [null, 529]),
  Breakpoint("horizontal", [530, null]),
]

const sizingBreakpoints = [Breakpoint("large", [900, null])]

const ArticleBlock = ({ children }) => {
  const breakpointRef = useRef()

  const layoutbreakpoint = useWidthBreakpoints(
    layoutBreakpoints,
    breakpointRef
  )[0]

  const sizebreakpoint = useWidthBreakpoints(
    sizingBreakpoints,
    breakpointRef
  )[0]

  const classes = classNames([
    styles.articleBlock,
    styles[layoutbreakpoint],
    styles[sizebreakpoint],
  ])

  const containerProps = {
    vertical: { space: 1 },
    horizontal: {
      space: 4,
      reverse: true,
      horizontal: true,
      distribute: "fill",
    },
  }

  return (
    <div className={classes} ref={breakpointRef}>
      <StackedContainer {...containerProps[layoutbreakpoint]}>
        {children}
      </StackedContainer>
    </div>
  )
}

const ArticleBlockImage = ({ src }) => {
  if (!src) return null

  const imageSrc = imageURL(src, {
    width: 570,
    height: 570,
    fit: "crop",
  })

  return (
    <div className={styles.articleBlockImageWrapper}>
      <div
        className={styles.articleBlockImage}
        style={{ backgroundImage: `url("${imageSrc}")` }}
      />
    </div>
  )
}

ArticleBlockImage.propTypes = {
  src: PropTypes.string.isRequired,
}

const ArticleBlockTitle = ({ children }) => {
  return <h3 className={styles.articleBlockTitle}>{children}</h3>
}

const ArticleBlockText = ({ children, maxLines }) => {
  const classes = classNames([
    styles.articleBlockText,
    maxLines && styles[`maxLines${maxLines}`],
  ])

  return (
    <div className={classes}>
      <p>{children}</p>
      <div className={styles.textMask} />
    </div>
  )
}

ArticleBlockText.defaultProps = {
  maxLines: 0,
}

ArticleBlockText.propTypes = {
  maxLines: PropTypes.oneOf([null, 2, 3, 4, 5]),
}

const ArticleBlockCTA = ({ route, link, children }) => {
  return (
    <CTAButton route={route} link={link}>
      {children}
    </CTAButton>
  )
}

ArticleBlockCTA.propTypes = {
  route: PropTypes.shape({ slug: PropTypes.string.isRequired }),
  link: PropTypes.string,
}

export {
  ArticleBlock,
  ArticleBlockImage,
  ArticleBlockTitle,
  ArticleBlockText,
  ArticleBlockCTA,
}
