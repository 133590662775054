import React from "react"
import PropTypes from "prop-types"

import classNames from "lib-vinomofo/utils/class-names"
import {
  useWidthBreakpoints,
  Breakpoint,
} from "lib-vinomofo/hooks/use-breakpoints"

import PageSection from "lib-vinomofo/components/page-section"
import ArticleGrid from "../article-grid"
import { SearchIcon, MicrophoneIcon } from "lib-vinomofo/components/icons"

import styles from "./search-bar.module.scss"

const breakpoints = [Breakpoint("large", [700, null])]

const SearchInputWrapper = ({ children }) => {
  const [breakpoint, breakpointRef] = useWidthBreakpoints(breakpoints)
  const classes = classNames([styles.searchBar, styles[breakpoint]])

  return (
    <div ref={breakpointRef} className={classes}>
      {children}
    </div>
  )
}

const VoiceInput = ({ active, onClick }) => {
  const classes = classNames([styles.voiceIcon, active && styles.active])
  return (
    <div className={classes} onClick={onClick}>
      <MicrophoneIcon />
    </div>
  )
}

VoiceInput.propTypes = {
  active: PropTypes.bool,
  onClick: PropTypes.func,
}

const TextInput = ({ placeholder, value, onChange }) => {
  return (
    <React.Fragment>
      <label className={styles.searchIcon} htmlFor="article-search-input">
        <SearchIcon />
      </label>
      <input
        type="search"
        id="article-search-input"
        name="q"
        className={styles.searchInput}
        placeholder={placeholder}
        value={value}
        onChange={e => onChange(e.target.value)}
      />
    </React.Fragment>
  )
}

TextInput.defaultProps = {
  onChange: () => {},
}

TextInput.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
}

const SearchResults = ({ articles }) => {
  const hasResults = articles && articles.length > 0
  return hasResults ? (
    <PageSection spaceBefore={2}>
      <ArticleGrid articles={articles} />
    </PageSection>
  ) : null
}

SearchResults.propTypes = {
  articles: PropTypes.array,
}

export { SearchInputWrapper, VoiceInput, TextInput, SearchResults }
