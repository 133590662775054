import React, { useState, useEffect } from "react"

import {
  SearchInputWrapper,
  TextInput,
  VoiceInput,
  SearchResults,
} from "../../components/search-bar"

import {
  InstantSearch,
  connectSearchBox,
  connectStateResults,
  createVoiceSearchHelper,
} from "react-instantsearch-dom"

const SearchInput = connectSearchBox(({ refine, currentRefinement }) => {
  const [_, setVoiceState] = useState({})
  const [voiceHelper, setVoiceHelper] = useState()

  useEffect(() => {
    const helper = createVoiceSearchHelper(
      {
        searchAsYouSpeak: true,
        onQueryChange: query => refine(query),
        onStateChange: (...args) => {
          setVoiceState(helper.getState())
        },
      },
      []
    )

    setVoiceHelper(helper)

    return helper.dispose
  }, [refine])

  return (
    <SearchInputWrapper>
      <TextInput
        placeholder={
          voiceHelper && voiceHelper.isListening()
            ? "I'm listening..."
            : "Search our stories..."
        }
        value={currentRefinement}
        onChange={value => refine(value)}
      />
      {voiceHelper && voiceHelper.isBrowserSupported() && (
        <VoiceInput
          active={voiceHelper.isListening()}
          onClick={() => {
            if (!voiceHelper.isListening()) {
              refine("")
            }
            voiceHelper.toggleListening()
          }}
        />
      )}
    </SearchInputWrapper>
  )
})

const HitsWrapper = connectStateResults(({ searchState, searchResults }) => {
  const hasQuery = searchState && searchState.query
  return hasQuery ? <SearchResults articles={searchResults.hits} /> : null
})

const Search = () => {
  return (
    <InstantSearch
      appId={process.env.GATSBY_ALGOLIA_APP_ID}
      apiKey={process.env.GATSBY_ALGOLIA_SEARCH_KEY}
      indexName={process.env.GATSBY_ALGOLIA_ARTICLES_INDEX}
    >
      <SearchInput />
      <HitsWrapper />
    </InstantSearch>
  )
}

export default Search
