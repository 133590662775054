import React from "react"
import PropTypes from "prop-types"

import Carousel from "nuka-carousel"

import { Breakpoint, useWidthBreakpoints } from "../../hooks/use-breakpoints"
import classNames from "../../utils/class-names"
import StackedContainer from "../stacked-container"

import "./nuka-carousel-overrides.scss"

import styles from "./featured-items-container.module.scss"

const StackedItems = ({ children }) => {
  return (
    <StackedContainer horizontal distribute="fill">
      {children}
    </StackedContainer>
  )
}

const SlidingItems = ({ children }) => {
  return (
    <Carousel
      autoplay={true}
      renderCenterRightControls={null}
      renderCenterLeftControls={null}
      wrapAround
    >
      {children}
    </Carousel>
  )
}

const breakpoints = [Breakpoint("wide", [940, null])]

const FeaturedItemsContainer = ({ children, title, subtitle }) => {
  const [breakpoint, breakpointRef] = useWidthBreakpoints(breakpoints)
  const ItemWrapper = "wide" === breakpoint ? StackedItems : SlidingItems

  const classes = classNames([
    styles.featuredItemsContainer,
    breakpoint && styles[breakpoint],
  ])

  return (
    <div className={classes} ref={breakpointRef}>
      <ItemWrapper>{children}</ItemWrapper>

      <div className={styles.foregroundElements}>
        <StackedContainer horizontal distribute="middle">
          <StackedContainer space={1}>
            {title && <h1 className={styles.title}>{title}</h1>}
            {title && subtitle && <div className={styles.divider} />}
            {subtitle && <p className={styles.subtitle}>{subtitle}</p>}
          </StackedContainer>
        </StackedContainer>
      </div>
    </div>
  )
}

FeaturedItemsContainer.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
}

export default FeaturedItemsContainer
