import React from "react"
import PropTypes from "prop-types"

import GridContainer from "lib-vinomofo/components/grid-container"
import Link from "lib-vinomofo/components/link"
import {
  useWidthBreakpoints,
  Breakpoint,
} from "lib-vinomofo/hooks/use-breakpoints"

import {
  ArticleCard,
  ArticleCardImage,
  ArticleCardTitle,
  ArticleCardText,
  ArticleCardCTA,
} from "../../components/article-card"

const breakpoints = [Breakpoint(2, [null, 649]), Breakpoint(3, [650, null])]

const ThreeUpArticles = ({ articles }) => {
  const [numCols, breakpointRef] = useWidthBreakpoints(breakpoints)

  return (
    <div ref={breakpointRef}>
      <GridContainer numCols={numCols} space={2}>
        {articles.map(({ title, slug, thumbnail, excerpt }) => (
          <ArticleCard key={slug}>
            <Link route={{ slug }}>
              <ArticleCardImage src={thumbnail} />
            </Link>
            <ArticleCardTitle>
              <Link route={{ slug }}>{title}</Link>
            </ArticleCardTitle>
            <ArticleCardText maxLines={4}>{excerpt}</ArticleCardText>
            <ArticleCardCTA route={{ slug }}>Keep reading</ArticleCardCTA>
          </ArticleCard>
        ))}
      </GridContainer>
    </div>
  )
}

ThreeUpArticles.propTypes = {
  articles: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      slug: PropTypes.string,
      thunbnail: PropTypes.string,
      excerpt: PropTypes.string,
    })
  ),
}

export default ThreeUpArticles
