import React from "react"
import PropTypes from "prop-types"

import StackedContainer from "lib-vinomofo/components/stacked-container"

import {
  ArticleBlock,
  ArticleBlockImage,
  ArticleBlockTitle,
  ArticleBlockText,
  ArticleBlockCTA,
} from "../../components/article-block"
import SizingWrapper from "lib-vinomofo/components/sizing-wrapper"

const OneUpArticle = ({ article }) => {
  const { slug, title, excerpt, thumbnail } = article

  return (
    <ArticleBlock>
      <ArticleBlockImage src={thumbnail} />
      <SizingWrapper height="100%">
        <StackedContainer space={1} distribute="middle">
          <ArticleBlockTitle>{title}</ArticleBlockTitle>
          <ArticleBlockText maxLines={5}>{excerpt}</ArticleBlockText>
          <ArticleBlockCTA route={{ slug }}>Read More</ArticleBlockCTA>
        </StackedContainer>
      </SizingWrapper>
    </ArticleBlock>
  )
}

OneUpArticle.propTypes = {
  article: PropTypes.shape({
    title: PropTypes.string,
    slug: PropTypes.string,
    thunbnail: PropTypes.string,
    excerpt: PropTypes.string,
  }),
}

export default OneUpArticle
