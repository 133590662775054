import React from "react"
import PropTypes from "prop-types"

import imageURL from "lib-vinomofo/utils/image-url"
import StackedContainer from "lib-vinomofo/components/stacked-container"

import styles from "./featured-item.module.scss"

const FeaturedItem = ({ title, tagline, imageSrc }) => {
  const src = imageURL(imageSrc, {
    height: 722,
    fit: "crop",
  })

  return (
    <div
      className={styles.featuredItem}
      style={{ backgroundImage: `url("${src}")` }}
    >
      <div className={styles.featuredItemGradient} />

      <div className={styles.featuredItemContent}>
        <StackedContainer distribute="end" space={1}>
          {tagline && <p className={styles.tagline}>{tagline}</p>}
          {title && <h3 className={styles.title}>{title}</h3>}
        </StackedContainer>
      </div>
    </div>
  )
}

FeaturedItem.propTypes = {
  title: PropTypes.string,
  tagline: PropTypes.string,
  imageSrc: PropTypes.string,
}

export default FeaturedItem
