import React from "react"
import PropTypes from "prop-types"

import WineGlass from "lib-vinomofo/components/icons/wine-glass"
import CTAButton from "lib-vinomofo/components/cta-button"

import styles from "./load-more.module.scss"

const LoadMore = ({ loading, onClick }) => {
  return (
    <div className={styles.loadMore}>
      <WineGlass />
      <div className={styles.buttonWrapper}>
        <hr />
        <CTAButton onClick={!loading && onClick} className={styles.button}>
          {loading ? "Loading" : "Load more"}
        </CTAButton>
        <hr />
      </div>
    </div>
  )
}

LoadMore.propTypes = {
  loading: PropTypes.bool,
  onClick: PropTypes.func,
}

export default LoadMore
