import React from "react"
import { graphql } from "gatsby"
import { withPrefix } from "gatsby-link"
import Helmet from "react-helmet"

import PageSection from "lib-vinomofo/components/page-section"
import {
  useMarketContext,
  hreflangTags,
} from "lib-vinomofo/contexts/market-context"
import imageURL from "lib-vinomofo/utils/image-url"

import PageLayout from "../components/page-layout"
import FeaturedArticles from "../partials/home/featured-articles"
import SearchBar from "../partials/home/search-bar"
import OneUpArticle from "../partials/home/one-up-article"
import ThreeUpArticles from "../partials/home/three-up-articles"
import ArticleGridWithBigOne from "../partials/home/article-grid-with-big-one"
import MoreArticles from "../partials/home/more-articles"
import parseContentfulPost from "lib-vinomofo/utils/parseContentfulPost";

export default ({ data }) => {
  const market = useMarketContext()

  const {
    site: { siteMetadata },
    allContentfulPost,
  } = data

  let articles = allContentfulPost.nodes.map(parseContentfulPost)
  articles = articles.filter(article => article.marketCode.includes(process.env.GATSBY_SITE_MARKET.toLowerCase()))

  const pageTitle = `Our articles and videos about wine | ${siteMetadata.title} ${market.name}`
  const pageUrl = `${siteMetadata.siteUrl}${withPrefix("/")}`
  const canonicalUrl = `${market.canonical_url}${withPrefix("/")}`
  const shareImage = imageURL(
    `${siteMetadata.siteUrl}${withPrefix("/icons/icon-512x512.png")}`
  )

  return (
    <PageLayout>
      <Helmet>
        <title>{pageTitle}</title>

        {hreflangTags.map(tag => {
          return (
            <link
              key={tag.lang}
              rel="alternate"
              href={`${tag.href}${withPrefix("/")}`}
              hreflang={tag.lang}
            />
          )
        })}

        <link rel="cannonical" href={canonicalUrl} />

        <meta name="description" content={siteMetadata.description} />

        <meta property="og:site_name" content={pageTitle} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Welcome to the Vinofiles." />
        <meta property="og:description" content={siteMetadata.description} />
        <meta property="og:url" content={pageUrl} />
        <meta property="og:image" content={shareImage} />
        <meta
          property="article:publisher"
          content="https://www.facebook.com/vinomofo"
        />
        <meta property="twitter:card" content="summary" />
        <meta property="twitter:title" content="Vinofiles" />
        <meta
          property="twitter:description"
          content={siteMetadata.description}
        />
        <meta property="twitter:url" content={pageUrl} />
        <meta property="twitter:image" content={shareImage} />
        <meta property="twitter:site" content={market.twitter} />
      </Helmet>

      <PageSection fullWidth>
        <FeaturedArticles articles={articles.splice(0, 3)} />
      </PageSection>

      <PageSection>
        <SearchBar />
      </PageSection>

      <PageSection spaceBefore={4}>
        <OneUpArticle article={articles.shift()} />
      </PageSection>

      <PageSection spaceBefore={4}>
        <ThreeUpArticles articles={articles.splice(0, 3)} />
      </PageSection>

      <PageSection spaceBefore={4}>
        <ArticleGridWithBigOne articles={articles.splice(0, 5)} />
      </PageSection>

      <PageSection spaceBefore={4}>
        <MoreArticles />
      </PageSection>
    </PageLayout>
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        description
        siteUrl
      }
    }
    allContentfulPost(
      sort: { fields: [publishDate], order: DESC },
      limit:40
    ) {
      nodes {
        id
        slug
        title
        markets {
          code
        }
        excerpt {
          excerpt
        }
        image {
          file {
            url
          }
        }
        thumbnail {
          file {
            url
          }
        }
        body__html {
          fields {
            readingTime {
              text
            }
          }
        }
      }
    }
  }
`
